//import "antd/dist/reset.css";
//import "./highcharts.config";
import "./leaflet.config";
import "./parse.config";

import { init, StorageAdapterLS } from "@opendash/core";
import { getCurrentLanguageSync } from "@opendash/i18n";
import { registerIconPack } from "@opendash/icons";
import { BDEPlugin } from "@opendash/plugin-bde";
import { FeedbackPlugin } from "@opendash/plugin-feedback";
import { GeoPlugin } from "@opendash/plugin-geo";
import { GeoPluginMapLibre } from "@opendash/plugin-geo-maplibre";
import { GTFSPlugin } from "@opendash/plugin-gtfs";
import { HighchartsPlugin } from "@opendash/plugin-highcharts";
import { KnowledgePlugin } from "@opendash/plugin-knowledge";
import { MobilityPlugin } from "@opendash/plugin-mobility";
import { MonitoringPlugin } from "@opendash/plugin-monitoring";
import { OpenServicePlugin } from "@opendash/plugin-openservice";
import { OpenwarePlugin } from "@opendash/plugin-openware";
import { ParsePlugin } from "@opendash/plugin-parse";
import { ParseMonitoringPlugin } from "@opendash/plugin-parse-monitoring";
import { PlotlyPlugin } from "@opendash/plugin-plotly";
import { TimeseriesPlugin } from "@opendash/plugin-timeseries";
import dayjs from "dayjs";
import OverViewComponent from "./components/OverViewComponent";
import clientselector from "./config";

import timezone from "dayjs/plugin/timezone";

init("opendash", async (factory) => {
  // Logo
  if (clientselector.getLogoImage()) {
    factory.ui.setLogoImage(clientselector.getLogoImage());
  }

  // factory.ui.setLogoLink("/");
  // factory.ui.setLogoLinkExternal(true);
  // factory.ui.disableFooter();

  // Icons
  // @ts-ignore
  registerIconPack(await import("@opendash/icons/dist/fa-regular.json"));
  // @ts-ignore
  registerIconPack(await import("@opendash/icons/dist/fa-solid.json"));

  // Translations:
  factory.registerLanguage("en", "English");
  factory.registerLanguage("zh_Hans", "Chinese");
  factory.registerLanguage("de", "Deutsch", "en", true);

  //@ts-ignore
  const params = new URL(document.location).searchParams;
  const embedded = params.get("embedded");

  if (embedded) {
    factory.ui.disableHeader();
    factory.ui.disableFooter();
  }

  factory.registerAntDesignTranslation(
    "en",
    () => import("antd/lib/locale/en_US")
  );

  factory.registerAntDesignTranslation(
    "de",
    () => import("antd/lib/locale/de_DE")
  );

  factory.registerAntDesignTranslation(
    "zh_Hans",
    () => import("antd/lib/locale/zh_CN")
  );

  // Translations

  factory.registerTranslationResolver(
    "de",
    "parse-custom",
    async () => await import("./translations/parse/de.json")
  );

  factory.registerTranslationResolver(
    "en",
    "parse-custom",
    async () => await import("./translations/parse/en.json")
  );

  factory.registerTranslationResolver(
    "zh_Hans",
    "parse-custom",
    async () => await import("./translations/parse/zh_Hans.json")
  );

  factory.registerTranslationResolver(
    "de",
    "widgets",
    async () => await import("./translations/widgets/de.json")
  );

  factory.registerTranslationResolver(
    "en",
    "widgets",
    async () => await import("./translations/widgets/en.json")
  );

  factory.registerTranslationResolver(
    "zh_Hans",
    "widgets",
    async () => await import("./translations/widgets/zh_Hans.json")
  );

  // Adapter + Plugins

  factory.registerDeviceStorageAdapter(new StorageAdapterLS());
  await factory.use(
    new ParsePlugin({
      authLdapActive: false,
      useLiveQueries: false,
      createAccount: true,
      lostPassword: true,
      usePagination: 20,
    })
  );
  await factory.use(new TimeseriesPlugin());

  await factory.use(new MonitoringPlugin());
  await factory.use(new GeoPlugin());
  await factory.use(new GeoPluginMapLibre());
  await factory.use(new OpenServicePlugin());
  await factory.use(new GTFSPlugin());
  await factory.use(new KnowledgePlugin());
  await factory.use(new PlotlyPlugin());
  await factory.use(new BDEPlugin());
  await factory.use(
    new FeedbackPlugin({
      feedbackURL: clientselector.getFeedbackURL(),
      feedbackAssigned: clientselector.getFeedbackAssigned(),
    })
  );

  await factory.use(
    new ParseMonitoringPlugin({
      liveQueries: false,
    })
  );

  await factory.use(
    new OpenwarePlugin({
      secure: clientselector.getOpenWareSecure(),
      host: clientselector.getOpenWareHost(),
      filterValuesOlderThanMS: -1,
      disableFeature: {
        menu: {
          SensorsGroup: false,
          DataPoints: false,
          DataSources: false,
        },
        slideshow: false,
        dataCollection: false,
        VKPI: false,
        forms: {
          dateBySensor: false,
        },
        reporting: false,
      },
    })
  );

  await factory.use(new HighchartsPlugin());

  addcss(`
  .ant-modal-close-x {margin-top:16}
  .ant-steps-item-icon span {line-height:32px!important}
  .ant-steps-item-icon svg {margin-top:7}
  .ant-drawer-body .data-item-boolean {margin-top:12} 
  .ant-drawer-body .data-item-percentage {margin-top:10}
  .leaflet-top {z-index:998!important} 
  body {margin:0 !important}
  `);

  //@ts-ignore
  factory.setPrimaryColor("#666767");
  await factory.use(new MobilityPlugin());

  // await factory.use(new GreisPlugin());

  // factory.ui.disableHeader();

  // Widgets

  //$monitoring.registerWidget(await import("./widgets/kpi-map"));

  // Routing:

  // factory.registerRoute({
  //   path: "/route/to/*",
  //   component: () => import("./path/to/component")
  // });

  factory.registerRoute({
    path: "/overview/*",
    props: {},
    component: async () => ({ default: OverViewComponent }),
  });
}).then((app) => {
  console.log("init open.DASH");
  dayjs.locale(getCurrentLanguageSync());
  dayjs.extend(timezone);
  dayjs.tz.setDefault("Europe/Berlin");
});

function addcss(css) {
  const head = document.getElementsByTagName("head")[0];
  const s = document.createElement("style");
  s.setAttribute("type", "text/css");
  s.appendChild(document.createTextNode(css));
  head.appendChild(s);
}
